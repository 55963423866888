.header {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset -1px -1px 0px 0px rgba(0, 0, 0, 0.1);
}

.blockHeader {
    overflow: hidden;
}

.tableHeader {
    height: 36px;
}

.cell {
    overflow: hidden;
    min-height: 36px;
}

.tableHeader .cell {
    height: 36px;
}

.cellHeader {
    flex: 1;
    width: 100%;
}

.toolbar {
    min-height: 0;
    width: 100%;
}

.toolbar > * {
    margin-right: 1px !important;
}

.alignLeft {
    justify-content: flex-start;
}

.alignCenter {
    justify-content: center;
}

.alignRight {
    justify-content: flex-end;
}
