.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.margin {
    margin-bottom: 10px;
}

.minWidth {
    min-width: 100px;
}

.fullWidth {
    width: 100%;
}

.label {
    position: unset !important;
    display: block;
    transform: none !important;
    margin-bottom: 0 !important;
}
