.root {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.name {
    flex: 1;
}
