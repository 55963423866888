.root {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    pointer-events: none;
}

.root > * {
    pointer-events: initial;
}

.hidden {
    visibility: 'hidden';
}
