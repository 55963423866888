.root {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.contextMenuButton {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0;
    transition: opacity 0.2s;
}

.root:hover .contextMenuButton {
    opacity: 1;
}
