.root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.colorPrimary {
    color: var(--primary-color);
}

.colorSecondary {
    color: var(--secondary-color);
}

.colorAction {
    color: rgba(0, 0, 0, 0.54);
}

.colorError {
    color: #f44336;
}

.colorDisabled {
    color: rgba(0, 0, 0, 0.26);
}

.fontSizeInherit {
    font-size: inherit;
}

.fontSizeSmall {
    font-size: 1.25rem;
}

.fontSizeLarge {
    font-size: 2.1875rem;
}
