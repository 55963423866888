.component {
    width: 100%;
}

.input {
    margin: 0 !important;
    padding: 0 !important;
}

:global .select__control {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    min-height: 22px !important;
    width: 100%;
}

:global .select__value-container {
    padding: 0 !important;
}

:global .select__indicator {
    padding: 0 !important;
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    color: inherit !important;
}

:global .select__menu {
    border-radius: 0 !important;
    z-index: 2 !important;
}

:global .select__menu-list {
    padding: 0 !important;
}

:global .select__option {
    font-size: 13px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

:global .select__option--is-focused {
    background-color: #e6e6e6 !important;
}

:global .select__option--is-selected {
    color: var(--primary-color) !important;
    background-color: #e6e6e6 !important;
}
