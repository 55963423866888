.root {
}

.tableRoot {
    vertical-align: bottom;
}

.blockRoot {
    display: flex;
    align-items: flex-end;
}

.timeline_wrapper {
    padding: 0;
}

.blockRoot .timeline_wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.timeline {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
}

.timeline_years,
.timeline_months {
    display: flex;
    width: 100%;

    align-items: center;
}

.timeline_years {
    border-right: 1px solid #e0e0e0;
}

.year {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-basis: 0%;
    overflow: hidden;
    border-top: 1px solid #e0e0e0;

    height: 25px;
}

.year_text {
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.empty {
    padding: 10px;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.blockRoot .empty {
    display: flex;

    align-items: center;
}

.month {
    overflow: hidden;
    flex: 1 1;
    height: 36px;

    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.month_text {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 13px;
    vertical-align: middle;
}

.tableRoot th {
    font-weight: normal;
}
