.root {
    display: flex;
    flex-direction: column;
}

.calendars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
}
