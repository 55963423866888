.root {
    display: flex;
    position: relative;
}

.background {
    transform: scale(1);
}

.root.checked .dot {
    transform: scale(1);
    transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.dot {
    left: 0;
    position: absolute;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
}
