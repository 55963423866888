.container {
  display: flex !important;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.w-100 {
  width: 100%;
}

.text-no-wrap {
  white-space: nowrap;
}