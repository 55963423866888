.root {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;

    break-inside: avoid;
}

.tableRoot {
}

.blockRoot {
    display: flex;
}

.header {
    background-color: white;
    border-right: 1px solid #e0e0e0;

    vertical-align: middle;
}

.blockRoot .header {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    z-index: 1;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
}

.header .drag_handle {
    text-align: center;
    width: 20px;
}

.header .expand {
    width: 20px;
    text-align: center;
}

.header .header_label {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    padding: 0px 10px;
    align-items: center;
    overflow: hidden;
}

.layers {
    padding: 0;
}

.layer {
    position: relative;
}

.layer_cells {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.layer_items {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
}

.cell {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #f2f2f2;
    border-right: 1px solid #e0e0e0;
}

.root .layer:last-child .cell {
    border-bottom: none;
}
