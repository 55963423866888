.row {
    background-color: white;
}

.row > *[role='cell'] {
    transition: background-color 0.2s;
}

.row:hover > *[role='cell'] {
    background-color: #f1fbfe;
}

.selected > *[role='cell'] {
    background-color: lightcyan !important;
}

.drag > *[role='cell'] {
    background-color: red !important;
}

.blockRow {
    width: -moz-fit-content;
    width: fit-content;
}

.tableRow {
}
