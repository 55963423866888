@media print {
    .root {
        position: absolute !important;
    }
}
.scrollPaper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.scrollBody {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
}
.scrollBody:after {
    width: 0;
    height: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
}
.container {
    height: 100%;
    outline: 0;
}
@media print {
    .container {
        height: auto;
    }
}
.paper {
    margin: 32px;
    position: relative;
    overflow-y: auto;
}

.draggable :global(.modal-header) {
    cursor: grab;
}

@media print {
    .paper {
        box-shadow: none;
        overflow-y: visible;
    }
}
.paperScrollPaper {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
}
.paperScrollBody {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
.paperWidthFalse {
    max-width: calc(100% - 64px);
}
.paperWidthXs {
    max-width: 444px;
}
@media (max-width: 507.95px) {
    .paperWidthXs.paperScrollBody {
        max-width: calc(100% - 64px);
    }
}
.paperWidthSm {
    max-width: 600px;
}
@media (max-width: 663.95px) {
    .paperWidthSm.paperScrollBody {
        max-width: calc(100% - 64px);
    }
}
.paperWidthMd {
    max-width: 960px;
}
@media (max-width: 1023.95px) {
    .paperWidthMd.paperScrollBody {
        max-width: calc(100% - 64px);
    }
}
.paperWidthLg {
    max-width: 1280px;
}
@media (max-width: 1343.95px) {
    .paperWidthLg.paperScrollBody {
        max-width: calc(100% - 64px);
    }
}
.paperWidthXl {
    max-width: 1920px;
}
@media (max-width: 1983.95px) {
    .paperWidthXl.paperScrollBody {
        max-width: calc(100% - 64px);
    }
}
.paperFullWidth {
    width: calc(100% - 64px);
}
.paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
}
.paperFullScreen.paperScrollBody {
    margin: 0;
    max-width: 100%;
}
.fade {
    width: 100%;
    height: 100%;
}
