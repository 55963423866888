.start-page-button {
  background-color: rgba(1, 102, 177, 255);
  color: rgba(255, 255, 255, 255);
  border: none;
  transition: background-color 1s, color 1s;
  border-radius: 5px;
  padding: 13px 15px;
  font-size: 12px;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.start-page-button:hover {
  background-color: rgba(255, 255, 255, 255);
  color: rgba(1, 102, 177, 255);
}

.start-page-button:active {
  opacity: 0.7;
}

.bolded-3d-text {
  color: transparent;
  background: rgb(189, 189, 189);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: 0px 1.63px 5.7px rgba(255, 255, 255);
  font-size: 12em;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  backdrop-filter: blur(0.407492px);
}
