.root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
}

.small {
    width: 28px;
    height: 28px;
    font-size: 1rem;
}

.medium {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
}

.big {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
}

.colorDefault {
    color: white;
    background-color: lightgray;
}

.rounded {
    border-radius: 4px;
}

.square {
    border-radius: 0;
}

.img {
    width: 100%;
    height: 100%;
    text-align: center;

    object-fit: cover;
    color: transparent;
    text-indent: 10000;
}

.fallback {
    width: "75%";
    height: "75%";
}
