.root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.absolute {
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
}

.inset {
    margin-left: 72px;
}

.light {
    background-color: rgba(0, 0, 0, 0.08);
}

.middle {
    margin-left: 16px;
    margin-right: 16px;
}

.vertical {
    width: 1px;
    height: 100%;
}

.flexItem {
    height: auto;
    align-self: stretch;
}

.wrapper {
    display: 'inline-block';
    padding-left: 8px;
    padding-right: 8px;
}

.wrapperVertical {
    padding-top: 8px;
    padding-bottom: 8px;
}
