.table {
    border-spacing: 0;
    border: none;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.fullWidth {
    width: 100%;
}

.regularTable {
    table-layout: fixed;
}

.blockTable {
    display: inline-block;
    min-width: unset !important;
    overflow-y: auto;
    overflow-x: hidden;
}
