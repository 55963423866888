.list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0;
}

.list_margin {
    margin-bottom: 1rem;
}

.header {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 6px;
    padding-bottom: 6px;
}

.item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-shrink: 0;
    color: #24272a;
    width: 100%;
}

.item_margin {
    margin-bottom: 10px;
}

.item_gutters {
    padding-left: 16px;
    padding-right: 16px;
}

.item_button {
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.item_button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.item > * {
    margin-right: 5px;
}

.item_text {
    flex: 1 !important;
    width: 100%;
}

.item > *:last-child {
    margin-right: 0;
}
