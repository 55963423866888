.item {
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;

    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;

    touch-action: none;
    transition: box-shadow 0.5s;

    top: 1px;
    bottom: 2px;
}

.item_overlap {
    overflow: hidden;
}

.item_overlap:first-child {
    margin-left: 2px;
}

.item_overlap:last-child {
    margin-right: 2px;
}

.item_selected {
    box-shadow: inset 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 5px 5px 1px rgba(0, 0, 0, 0.14),
        0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    z-index: 1;
}

.item_body {
    flex: 1;
    margin: 2px 0;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow: hidden;
}
