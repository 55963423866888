.root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}
.root:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.root.disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
}
@media (hover: none) {
    .root:hover {
        background-color: transparent;
    }
}
.edgeStart {
    margin-left: -12px;
}
.sizeSmall.edgeStart {
    margin-left: -3px;
}
.edgeEnd {
    margin-right: -12px;
}
.sizeSmall.edgeEnd {
    margin-right: -3px;
}
.colorInherit {
    color: inherit;
}
.colorPrimary {
    color: var(--primary-color);
}
.colorPrimary:hover {
    background-color: rgba(25, 118, 210, 0.04);
}
@media (hover: none) {
    .colorPrimary:hover {
        background-color: transparent;
    }
}
.colorSecondary {
    color: var(--secondary-color);
}
.colorSecondary:hover {
    background-color: rgba(220, 0, 78, 0.04);
}
@media (hover: none) {
    .colorSecondary:hover {
        background-color: transparent;
    }
}
.sizeSmall {
    padding: 3px;
    font-size: 14px;
    width: 24px;
    height: 24px;
}
.label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}
