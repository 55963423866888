.drag_handle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding-left: 8px;
}

.expandCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.expandCell > * {
    margin-right: 3px;
}

.expandCell > *:last-child {
    margin-right: 0;
}

.dragColumn {
    max-width: 34px;
}

.expanderColumn {
    max-width: 34px;
}

.selectColumn {
    max-width: 42px;
}
