.searchIcon {
    cursor: pointer;
    z-index: 100;
}

.searchInput {
    padding-left: 20px !important;
}

.searchInputContainer {
    width: auto !important;
}
