.root {
    display: flex;
    align-items: center;
    padding: 8px;
}

.spacing > *:not(:first-child) {
    margin-left: 8px;
}

.hiddenAction {
    position: relative;
}

.hiddenAction > * {
    opacity: 0;
    transition: opacity 0.2s;
}

:global(.card:hover) .hiddenAction > * {
    opacity: 1;
}
