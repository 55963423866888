.thumb.open .offset {
    transform: scale(1) translateY(-10px);
}

.offset {
    top: -34px;
    z-index: 1;
    position: absolute;
    font-size: 0.75rem;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
    transform-origin: bottom center;
}

.circle {
    width: 32px;
    height: 32px;
    display: flex;
    transform: rotate(-45deg);
    align-items: center;
    border-radius: 50% 50% 50% 0;
    justify-content: center;
    background-color: currentColor;
}

.label {
    color: #fff;
    transform: rotate(45deg);
}
