.container {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    line-height: 1.4;
}

.underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin-bottom: 1px;
}

.underline:focus-within {
    border-bottom-width: 2px;
    border-bottom-color: var(--primary-color);
    margin-bottom: 0px;
}

.disabled {
    color: rgba(0, 0, 0, 0.26);
    border-bottom-style: dotted;
}

.input_disabled {
}

.fullWidth {
    width: 100%;
}

.input {
    border-radius: 0;
    background: none;
    color: inherit;
    min-width: 0;
    width: 100%;
    height: 100%;
    min-height: 22px;
    display: inline-flex;
    border: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;
    padding: 4px 0;
    box-sizing: content-box;
}

.container > * {
    margin-left: 2.5px;
    margin-right: 2.5px;
}
