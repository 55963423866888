.root {
    break-inside: auto;
}

.tableRoot {
    overflow: hidden;
}

.blockRoot {
    display: flex;
    flex: 1;
    flex-direction: column;
}
