.node {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    background: inherit;
    color: inherit;
}

.handle {
}

.handle_disabled {
    opacity: 0;
}

.resize {
    position: absolute;
    right: -12px;
    bottom: -12px;
    padding: 0px;
    background: #e6e6e6;
}

:global .react-flow__node-default,
:global .react-flow__node-group,
:global .react-flow__node-input,
:global .react-flow__node-output {
    border: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
}
