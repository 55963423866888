.root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.root:focus {
    outline: none;
}

.root::-moz-focus-inner {
    border-style: none;
}

.root.disabled {
    cursor: default;
    pointer-events: none;
}
@media print {
    .root {
        -webkit-print-color-adjust: exact;
    }
}
