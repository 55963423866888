.root {
    background-color: transparent;
    text-align: center;
    padding: 2px;
    border: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
}

.disabled {
    cursor: initial;
}

.empty {
    opacity: 0;
    pointer-events: none;
}

.unavailable {
    opacity: 0.5;
}

.today {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.selected {
    background-color: var(--primary-color);
    color: #fff;
}

.inRange {
    background-color: #ededed;
}
