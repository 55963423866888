.container {
    display: flex;
    flex-direction: column;
}

.fullWidth {
    width: 100%;
}

.label {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
}

.labelText {
    margin-left: 5px;
}

.root {
    color: rgba(0, 0, 0, 0.54);
    padding: 3px;
    width: 24px;
    height: 24px;
}

.indeterminate {
}

.colorPrimary.checked {
    color: var(--primary-color);
}

.colorPrimary.disabled {
    color: rgba(0, 0, 0, 0.26);
}

.colorPrimary.checked:hover {
    background-color: rgba(25, 118, 210, 0.04);
}

@media (hover: none) {
    .colorPrimary.checked:hover {
        background-color: transparent;
    }
}

.colorSecondary.checked {
    color: var(--secondary-color);
}

.colorSecondary.disabled {
    color: rgba(0, 0, 0, 0.26);
}

.colorSecondary.checked:hover {
    background-color: rgba(220, 0, 78, 0.04);
}

@media (hover: none) {
    .colorSecondary.checked:hover {
        background-color: transparent;
    }
}
