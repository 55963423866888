/* .group .accordion {
    margin-top: -1px;
} */

.group .accordion:first-child {
    margin-top: 0px;
}

.accordion {
    display: flex;
    flex-direction: column;
}

.margin {
    margin: 10px 0;
}

.summary {
    cursor: pointer;
    padding: 0 5px;
    padding-left: 15px;
    background-color: white !important;
    transition: background-color 0.2s;
}

.summary:hover {
    background-color: #f1fbfe !important;
}

.summary_dnd {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    opacity: 0.54;

    margin-left: -15px;
    height: 42px;
    width: 20px;

    cursor: move;
}

.summary_icon {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(0deg);
}

.summary_icon_expanded {
    transform: rotate(180deg);
}

.details_margin {
    margin: 10px;
}

.details_content {
}
