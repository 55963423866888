.cell {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    font-weight: normal;
}

.cell:last-child {
    border-right: 0;
}

.tableCell {
}

.blockCell {
    display: flex;
    align-items: center;
}

.relative {
    position: relative;
}

.resizer {
    display: inline-block;
    background: transparent;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);

    touch-action: none;
}

.resizer::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    width: 1px;
    background-color: #e0e0e0;
}

.resizer.isResizing::after {
    background-color: black;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}
