/* https://fkhadra.github.io/react-toastify/how-to-style/#override-css-variables */
.Toastify__toast-theme--colored.Toastify__toast--default{
    background: #42454A;
    color: #FFFFFF;
}

.Toastify__toast-container {
    width: max-content;
}

.Toastify__toast-body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}