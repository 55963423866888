.toolbar {
    display: flex;
    align-items: center;
    min-height: 42px;
}

.wrap {
    flex-wrap: wrap;
}

.padding {
    padding: 0 8px;
}

.toolbar > * {
    margin-right: 10px !important;
}

.toolbar > *:last-child {
    margin-right: 0 !important;
}

.toolbar_wrapper {
    padding: 0 8px;
    margin-bottom: 8px;
    height: auto;
}

.disableMargin {
    margin-bottom: 0;
}

.toolbar :global(.e-separator) {
    margin: 7.5px 3px;
    min-height: 27px;
    min-width: 1px;
    height: calc(100% - 15px);
    vertical-align: middle;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0.5px 0 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.separator {
    margin: 7.5px 3px;
    min-height: 27px;
    min-width: 1px;
    height: calc(100% - 15px);
    vertical-align: middle;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0.5px 0 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}