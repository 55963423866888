.body {
    overflow: auto;
}

.regularBody {
}

.blockBody {
    overflow: auto;
}
