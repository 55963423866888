.wrapper {
    position: relative;
}

.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hiddenContent {
    opacity: 0;
}
