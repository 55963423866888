.blockTable {
  display: block !important;
  min-width: unset !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.regularTable {
  table-layout: fixed;
  display: table;
}

.fullWidth {
  width: 100%;
}

.blockHead {
  display: block !important;
  overflow: hidden;
}

.blockBody {
  display: block !important;
  overflow: auto;
  width: 100%;
}

.blockRow {
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 100% !important;
}

.cell {
  /* nothing: none; */
}

.headerCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: 500 !important;
}

.headerCell > * {
  font-weight: 500 !important;
}

.blockCell {
  display: flex !important;
  align-items: center;
}

.cellAlignLeft,
.cellAlignLeft > div {
  justify-content: flex-start;
}

.cellAlignCenter,
.cellAlignLeft > div {
  justify-content: center;
}

.cellAlignRight,
.cellAlignLeft > div {
  justify-content: flex-end;
}
