.days {
    display: grid;
    grid-template-columns: repeat(7, 28px);
    grid-template-rows: repeat(7, 26px);
    grid-column-gap: 0px;
    grid-row-gap: 2px;
}

.weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    font-family: monospace;
}
