.root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 10px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}
.root:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}
.root.disabled {
    color: rgba(0, 0, 0, 0.26);
}
@media (hover: none) {
    .root:hover {
        background-color: transparent;
    }
}
.root:hover.disabled {
    background-color: transparent;
}
.label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}
.text {
    padding: 6px 8px;
}
.textPrimary {
    color: var(--primary-color);
}
.textPrimary:hover {
    background-color: rgba(204, 68, 75, 0.04);
}
@media (hover: none) {
    .textPrimary:hover {
        background-color: transparent;
    }
}
.textSecondary {
    color: var(--secondary-color);
}
.textSecondary:hover {
    background-color: rgba(220, 0, 78, 0.04);
}
@media (hover: none) {
    .textSecondary:hover {
        background-color: transparent;
    }
}
.outlined {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
}
.outlined.disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.outlined:hover {
    background-color: rgba(0, 0, 0, 0.12);
}
.outlinedPrimary {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
@media (hover: none) {
    .outlined:hover {
        background-color: transparent;
    }
}
.outlinedSecondary {
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}
.outlinedError {
    color: var(--error-color);
    border: 1px solid var(--error-color);
}
.outlinedError.disabled,
.outlinedSecondary.disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #e0e0e0;
}
.contained:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #d5d5d5;
}
.contained.focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.contained:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.contained.disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
@media (hover: none) {
    .contained:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        background-color: #e0e0e0;
    }
}
.contained:hover.disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
.containedPrimary {
    color: #fff;
    background-color: var(--primary-color);
}
.containedPrimary:hover {
    color: gray;
}
@media (hover: none) {
    .containedPrimary:hover {
        background-color: var(--primary-color);
    }
}
.containedSecondary {
    color: #fff;
    background-color: var(--secondary-color);
}
.containedSecondary:hover {
    color: gray;
}
@media (hover: none) {
    .containedSecondary:hover {
        background-color: var(--secondary-color);
    }
}
.disableElevation {
    box-shadow: none;
}
.disableElevation:hover {
    box-shadow: none;
}
.disableElevation.focusVisible {
    box-shadow: none;
}
.disableElevation:active {
    box-shadow: none;
}
.disableElevation.disabled {
    box-shadow: none;
}
.colorInherit {
    color: inherit;
    border-color: currentColor;
}
.textSizeSmall {
    padding: 4px 5px;
    font-size: 0.8125rem;
}
.textSizeLarge {
    padding: 8px 11px;
    font-size: 0.9375rem;
}
.outlinedSizeSmall {
    padding: 3px 9px;
    font-size: 0.8125rem;
}
.outlinedSizeLarge {
    padding: 7px 21px;
    font-size: 0.9375rem;
}
.containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
}
.containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
}
.fullWidth {
    width: 100%;
}
.startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
    font-size: 1em;
}

.sizeSmall .startIcon {
    margin-left: -2px;
    margin-right: 4px;
}

.endIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
    font-size: 1em;
}

.sizeSmall .endIcon {
    margin-left: 4px;
    margin-right: -2px;
}
