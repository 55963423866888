.root {
    display: inline-block;
}

.static {
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.indeterminate {
    animation: keyframes-circular-rotate 1.4s linear infinite;
}

.determinate {
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.colorPrimary {
    color: var(--primary-color);
}

.colorSecondary {
    color: var(--secondary-color);
}

.colorError {
    color: var(--error-color);
}

.colorContrast {
    color: white;
}

.svg {
    display: block;
}

.circle {
    stroke: currentColor;
}

.circleStatic {
    transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.circleIndeterminate {
    animation: keyframes-circular-dash 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0px;
}

.circleDeterminate {
    transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@keyframes keyframes-circular-rotate {
    0% {
        transform-origin: 50% 50%;
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes keyframes-circular-dash {
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0px;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
}
.circleDisableShrink {
    animation: none;
}
