.help-feedback {
  color: var(--text-secondary, rgba(0, 0, 0, 0.54));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.5px;
}

.help-link {
  color: var(--secondary-dark, #0288d1);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 34.5px;
}
