.root {
    height: 4px;
    overflow: hidden;
    position: relative;
}

@media print {
    .root {
        -webkit-print-color-adjust: exact;
    }
}

.colorPrimary {
    background-color: #d69597;
}

.colorSecondary {
    background-color: rgb(241, 158, 187);
}

.buffer {
    background-color: transparent;
}

.query {
    transform: rotate(180deg);
}

.dashed {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: keyframes-buffer 3s infinite linear;
    margin-top: 0;
}

.dashedColorPrimary {
    background-size: 10px 10px;
    background-image: radial-gradient(#d69597 0%, #d69597 16%, transparent 42%);
    background-position: 0 -23px;
}

.dashedColorSecondary {
    background-size: 10px 10px;
    background-image: radial-gradient(rgb(241, 158, 187) 0%, rgb(241, 158, 187) 16%, transparent 42%);
    background-position: 0 -23px;
}

.bar {
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
}

.barColorPrimary {
    background-color: var(--primary-color);
}

.barColorSecondary {
    background-color: var(--secondary-color);
}

.bar1Indeterminate {
    width: auto;
    animation: keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.bar1Determinate {
    transition: transform 0.4s linear;
}

.bar1Buffer {
    z-index: 1;
    transition: transform 0.4s linear;
}

.bar2Indeterminate {
    width: auto;
    animation: keyframes-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}

.bar2Buffer {
    transition: transform 0.4s linear;
}

@keyframes keyframes-indeterminate1 {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes keyframes-indeterminate2 {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes keyframes-buffer {
    0% {
        opacity: 1;
        background-position: 0 -23px;
    }
    50% {
        opacity: 0;
        background-position: 0 -23px;
    }
    100% {
        opacity: 1;
        background-position: -200px -23px;
    }
}

.determinate {
}

.indeterminate {
}
