.root {
    margin: 0 30px;
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
}

.item {
    display: flex;
    align-items: center;

    height: 30px;
    margin-left: 10px;
    cursor: pointer;
}

.disabled {
    opacity: 0.3;
}

.preview {
    margin-right: 3px;
}

.name {
    display: flex;
    flex: 1;
    overflow: hidden;
}
