.popper {
    z-index: 1500;
    pointer-events: none;
}
.popperInteractive {
    pointer-events: auto;
}
.popperArrow[x-placement*='bottom'] .arrow {
    top: 0;
    left: 0;
    margin-top: -0.71em;
    margin-left: 4px;
    margin-right: 4px;
}
.popperArrow[x-placement*='top'] .arrow {
    left: 0;
    bottom: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: -0.71em;
}
.popperArrow[x-placement*='right'] .arrow {
    left: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-left: -0.71em;
    margin-bottom: 4px;
}
.popperArrow[x-placement*='left'] .arrow {
    right: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-right: -0.71em;
    margin-bottom: 4px;
}
.popperArrow[x-placement*='left'] .arrow::before {
    transform-origin: 0 0;
}
.popperArrow[x-placement*='right'] .arrow::before {
    transform-origin: 100% 100%;
}
.popperArrow[x-placement*='top'] .arrow::before {
    transform-origin: 100% 0;
}
.popperArrow[x-placement*='bottom'] .arrow::before {
    transform-origin: 0 100%;
}
.tooltip {
    color: #fff;
    padding: 4px 8px;
    font-size: 0.625rem;
    max-width: 300px;
    word-wrap: break-word;
    font-weight: 500;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: rgba(97, 97, 97, 0.9);
}
.tooltipArrow {
    margin: 0;
    position: relative;
}
.arrow {
    color: rgba(97, 97, 97, 0.9);
    width: 1em;
    height: 0.71em;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
}
.arrow::before {
    width: 100%;
    height: 100%;
    margin: auto;
    content: '';
    display: block;
    transform: rotate(45deg);
    background-color: currentColor;
}
.touch {
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.14286em;
}
.tooltipPlacementLeft {
    margin: 0 24px;
    transform-origin: right center;
}
@media (min-width: 600px) {
    .tooltipPlacementLeft {
        margin: 0 14px;
    }
}
.tooltipPlacementRight {
    margin: 0 24px;
    transform-origin: left center;
}
@media (min-width: 600px) {
    .tooltipPlacementRight {
        margin: 0 14px;
    }
}
.tooltipPlacementTop {
    margin: 24px 0;
    transform-origin: center bottom;
}
@media (min-width: 600px) {
    .tooltipPlacementTop {
        margin: 14px 0;
    }
}
.tooltipPlacementBottom {
    margin: 24px 0;
    transform-origin: center top;
}
@media (min-width: 600px) {
    .tooltipPlacementBottom {
        margin: 14px 0;
    }
}
