.root {
    display: inline-flex;
    border-radius: 4px;
}
.contained {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.disableElevation {
    box-shadow: none;
}
.fullWidth {
    width: 100%;
}
.vertical {
    flex-direction: column;
}
.grouped {
    min-width: 30px;
}
.groupedHorizontal:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.groupedHorizontal:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.groupedVertical:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.groupedVertical:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.groupedTextHorizontal:not(:last-child) {
    /* border-right: 1px solid rgba(0, 0, 0, 0.23); */
}
.groupedTextVertical:not(:last-child) {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.23); */
}
.groupedTextPrimary:not(:last-child) {
    border-color: rgba(25, 118, 210, 0.5);
}
.groupedTextSecondary:not(:last-child) {
    border-color: rgba(220, 0, 78, 0.5);
}
.groupedOutlinedHorizontal:not(:first-child) {
    margin-left: -1px;
}
.groupedOutlinedHorizontal:not(:last-child) {
    border-right-color: transparent;
}
.groupedOutlinedVertical:not(:first-child) {
    margin-top: -1px;
}
.groupedOutlinedVertical:not(:last-child) {
    border-bottom-color: transparent;
}
.groupedOutlinedPrimary:hover {
    border-color: var(--primary-color);
}
.groupedOutlinedSecondary:hover {
    border-color: var(--secondary-color);
}
.groupedContained {
    box-shadow: none;
}
.groupedContainedHorizontal:not(:last-child) {
    border-right: 1px solid #bdbdbd;
}
.groupedContainedHorizontal:not(:last-child).disabled {
    border-right: 1px solid rgba(0, 0, 0, 0.26);
}
.groupedContainedVertical:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
}
.groupedContainedVertical:not(:last-child).disabled {
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}
.groupedContainedPrimary:not(:last-child) {
    border-color: rgb(17, 82, 147);
}
.groupedContainedSecondary:not(:last-child) {
    border-color: rgb(154, 0, 54);
}
