.tab {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab:last-child {
    margin-right: 0;
}

.active,
.tab:hover {
    text-decoration: none;
}

.disabled {
    opacity: 0.5;
}

.vertical {
    margin-right: 0;
}

.default,
.default.disabled:hover,
.default.disabled.active {
    cursor: default;
    font-size: 20px;
    line-height: 24px;
    color: #24272a;
    border-bottom: 3px solid transparent;
    padding: 10px 0;
}

.secondary,
.secondary.disabled:hover,
.secondary.disabled.active {
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    padding: 7px 14px;
    color: #24272a;
    margin-right: 0;
    text-transform: uppercase;
}

.default:hover,
.default.active {
    cursor: pointer;
    border-bottom: 3px solid var(--primary-color);
    color: #24272a;
}

.secondary:hover,
.secondary.active {
    cursor: pointer;
    background: #e9e9e9;
    color: var(--primary-color);
}

.default.vertical,
.default.vertical.disabled:hover,
.default.vertical.disabled.active {
    border-bottom: none;
    border-right: 3px solid transparent;
}

.default.vertical:hover,
.default.vertical.active {
    cursor: pointer;
    border-bottom: none;
    border-right: 3px solid var(--primary-color);
}

.tab > svg:first-child {
    margin-right: 10px;
}

.tab > svg:last-child {
    margin-right: 0;
}
