.root {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 48px;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
}
@media (min-width: 600px) {
    .root {
        min-height: auto;
    }
}
.dense {
    font-size: 0.875rem;
    min-height: auto;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.gutters {
}

.selected {
}
