.root {
    width: 100%;
}

.container {
    position: relative;
    width: 100%;
}

.overflowHidden {
    overflow: hidden;
}
