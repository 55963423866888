.container {
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.fullWidth {
    width: 100%;
}

.label {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
}

.labelText {
    flex: 1;
    margin-right: 5px;
}

.root {
    width: 58px;
    height: 38px;
    display: inline-flex;
    padding: 12px;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
}

@media print {
    .root {
        -webkit-print-color-adjust: exact;
    }
}

.edgeStart {
    margin-left: -8px;
}

.edgeEnd {
    margin-right: -8px;
}

.switchBase {
    padding: 3px;
    width: 38px;
    height: 38px;
    top: 0;
    left: 0;
    color: #fafafa;
    z-index: 1;
    position: absolute;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.switchBase.checked {
    transform: translateX(20px);
}

.switchBase.disabled {
    color: #bdbdbd;
}

.switchBase.checked + .track {
    opacity: 0.5;
}

.switchBase.disabled + .track {
    opacity: 0.12;
}

.colorPrimary.checked {
    color: var(--primary-color);
}

.colorPrimary.disabled {
    color: #bdbdbd;
}

.colorPrimary.checked + .track {
    background-color: var(--primary-color);
}

.colorPrimary.disabled + .track {
    background-color: #000;
}

.colorPrimary.checked:hover {
    background-color: rgba(25, 118, 210, 0.04);
}

@media (hover: none) {
    .colorPrimary.checked:hover {
        background-color: transparent;
    }
}

.colorSecondary.checked {
    color: var(--secondary-color);
}

.colorSecondary.disabled {
    color: #bdbdbd;
}

.colorSecondary.checked + .track {
    background-color: var(--secondary-color);
}

.colorSecondary.disabled + .track {
    background-color: #000;
}

.colorSecondary.checked:hover {
    background-color: rgba(220, 0, 78, 0.04);
}

@media (hover: none) {
    .colorSecondary.checked:hover {
        background-color: transparent;
    }
}

.sizeSmall {
    width: 40px;
    height: 24px;
    padding: 7px;
}

.sizeSmall .thumb {
    width: 16px;
    height: 16px;
}

.sizeSmall .switchBase {
    padding: 4px;
}

.sizeSmall .switchBase.checked {
    transform: translateX(16px);
}

.input {
    left: -100%;
    width: 300%;
}

.thumb {
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    background-color: currentColor;
}

.track {
    width: 100%;
    height: 100%;
    opacity: 0.38;
    z-index: -1;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 7px;
    background-color: #000;
}
