.tabs {
    display: flex;
    align-items: center;
}

.margin {
    margin-bottom: 10px;
}

.vertical {
    flex-direction: column;
    align-items: stretch;
}

.tab_content_hidden {
    display: none;
}
