.root {
    color: var(--primary-color);
    width: 100%;
    cursor: pointer;
    height: 2px;
    display: inline-block;
    padding: 13px 0;
    position: relative;
    box-sizing: content-box;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
}
.root.disabled {
    color: #bdbdbd;
    cursor: default;
    pointer-events: none;
}
.root.vertical {
    width: 2px;
    height: 100%;
    padding: 0 13px;
}
@media (pointer: coarse) {
    .root {
        padding: 20px 0;
    }
    .root.vertical {
        padding: 0 20px;
    }
}
@media print {
    .root {
        -webkit-print-color-adjust: exact;
    }
}
.colorSecondary {
    color: var(--secondary-color);
}
.marked {
    margin-bottom: 20px;
}
.marked.vertical {
    margin-right: 20px;
    margin-bottom: auto;
}
.rail {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}
.vertical .rail {
    width: 2px;
    height: 100%;
}
.track {
    height: 2px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}
.vertical .track {
    width: 2px;
}
.trackFalse .track {
    display: none;
}
.trackInverted .track {
    background-color: rgb(167, 202, 237);
}
.trackInverted .rail {
    opacity: 1;
}
.thumb {
    width: 12px;
    height: 12px;
    display: flex;
    outline: 0;
    position: absolute;
    box-sizing: border-box;
    margin-top: -5px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: currentColor;
}
.thumb::after {
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    content: "";
    position: absolute;
    border-radius: 50%;
}
.thumb.focusVisible,
.thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(25, 118, 210, 0.16);
}
.thumb.active {
    box-shadow: 0px 0px 0px 14px rgba(25, 118, 210, 0.16);
}
.thumb.disabled {
    width: 8px;
    height: 8px;
    margin-top: -3px;
    margin-left: -4px;
}
.vertical .thumb {
    margin-left: -5px;
    margin-bottom: -6px;
}
.vertical .thumb.disabled {
    margin-left: -3px;
    margin-bottom: -4px;
}
.thumb.disabled:hover {
    box-shadow: none;
}
@media (hover: none) {
    .thumb.focusVisible,
    .thumb:hover {
        box-shadow: none;
    }
}
.thumbColorSecondary.focusVisible,
.thumbColorSecondary:hover {
    box-shadow: 0px 0px 0px 8px rgba(220, 0, 78, 0.16);
}
.thumbColorSecondary.active {
    box-shadow: 0px 0px 0px 14px rgba(220, 0, 78, 0.16);
}
.valueLabel {
    left: calc(-50% - 4px);
}
.mark {
    width: 2px;
    height: 2px;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}
.markActive {
    opacity: 0.8;
    background-color: #fff;
}
.markLabel {
    top: 26px;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    font-size: 0.875rem;
    transform: translateX(-50%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.01071em;
}
.vertical .markLabel {
    top: auto;
    left: 26px;
    transform: translateY(50%);
}
@media (pointer: coarse) {
    .markLabel {
        top: 40px;
    }
    .vertical .markLabel {
        left: 31px;
    }
}
.markLabelActive {
    color: rgba(0, 0, 0, 0.87);
}
