  .zoom-button-controllers {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 5px;
    right: 42px;
  }

  .zoom-button-controllers button {
    text-align: center;
    border: none;
    padding: 0 4px;
    cursor: pointer;
    margin-left: 7px;
    background-color: #F0F0F0;
    color: black;
    height: 26px;
    min-width: 26px;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .zoom-controller {
    touch-action: none;
  }
  .grabbing {
    cursor:grabbing;
  }
  .grab {
    cursor:grab;
  }

  .chart-mini-map {
    cursor: all-scroll;
  }
