.root {
}

.tableRoot {
    table-layout: fixed;
    width: 100%;
}

.blockRoot {
    display: flex;
    flex-direction: column;
    break-inside: auto;
}
