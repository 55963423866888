.root {
    display: flex;
    align-items: center;
    padding: 16px;
}

.relative {
    position: relative;
}

.title {
}

.subheader {
}

.avatar {
    flex: 0 0 auto;
    margin-right: 16px;
}

.action {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: -4px;
    margin-right: -8px;
    margin-bottom: -4px;
}

.content {
    flex: 1 1 auto;
    max-width: 100%;
}

.overlay {
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

:global(.card:hover) .overlay {
    background-color: rgb(255 255 255 / 50%);
    backdrop-filter: blur(3px);
}
