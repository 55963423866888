.root {
    margin: 0;
}

body,
:global(p),
.body2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.body1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.caption {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.button {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0;
}

.inherit {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}

:global(h1),
.h1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.167;
    letter-spacing: -0.01562em;
}

:global(h2),
.h2 {
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: -0.00833em;
}

:global(h3),
.h3 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
}

:global(h4),
.h4 {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

:global(h5),
.h5 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
}

:global(h6),
.h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.subtitle1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
}

.subtitle2 {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: 0.00714em;
}

.overline {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
}

.srOnly {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.alignJustify {
    text-align: justify;
}

.noWrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gutterBottom {
    margin-bottom: 0.35em;
}

.paragraph {
    margin-bottom: 16px;
}

.colorInherit {
    color: inherit;
}

.colorPrimary {
    color: var(--primary-color);
}

.colorSecondary {
    color: var(--secondary-color);
}

.colorTextPrimary {
    color: rgba(0, 0, 0, 0.87);
}

.colorTextSecondary {
    color: rgba(0, 0, 0, 0.54);
}

.colorError {
    color: var(--error-color);
}

.displayInline {
    display: inline;
}

.displayBlock {
    display: block;
}

.highlight {
    padding: 3px;
    border-radius: 6px;
}

.highlight.colorPrimary {
    background-color: #f6dfe0;
}
