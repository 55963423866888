.root {
    position: absolute;

    bottom: -9px;
    left: 0;
    right: 0;

    height: 2px;

    box-sizing: content-box;
    background-color: var(--primary-color) !important;
    background-clip: content-box;

    border: 8px solid transparent;
    border-left-width: 0;
    border-right-width: 0;

    /* display: none; */
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s;
}

.root_top {
    top: -9px;
}

.root:hover {
    /* display: block; */
    opacity: 1;
    transition-delay: 0.5s;
}

.toolbar {
    height: 2px !important;
    min-height: 0px !important;
}
